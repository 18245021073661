
::-webkit-scrollbar {
  display: none;
}
.create-exam {
  height: 100%;
  overflow-y: auto;
  padding: 11px 19px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .exam-hr {
    margin-top: 26px;
    border: none;
    border-bottom: 2px solid rgba(241, 245, 255, 1);
  }
  .exam-main {
    margin-top: 30px;
    .exam-title {
      display: flex;
      align-items: center;

      .green-block {
        width: 5px;
        height: 20px;
        background: #2dc079;
        border-radius: 3px;
        margin-right: 10px;
      }

      span {
        line-height: 1;
        &:first-of-type {
          font-size: 18px;
        }
        &:last-of-type {
          font-size: 16px;
          color: #999;
        }
      }
    }

    .exam-setting {
      margin: 41px 0 44px 0;
    }
    .change-btn {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    .student-table {
      margin: 15px 0 0 25px;
      border: 1px solid rgba(220, 220, 220, 1);
      width: 400px;
      height: 400px;
      overflow-y: auto;
      .student-check {
        display: flex;
        align-items: center;
        margin: 20px;
      }
    }

    .exam-time {
      position: relative;
      .exam-minute {
        position: absolute;
        right: -10%;
      }
    }

    .paper-category {
      display: flex;
      margin-bottom: 20px;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
